import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Button,
  Avatar,
  Typography,
} from '@mui/material';
import ElyfiSubgraph, { IAssetBond } from 'clients/ElyfiSubgraph';
import Slate from 'clients/Slate';
import { useEffect, useState } from 'react';
import { parseTokenId } from 'utils/parseTokenId';
import { toUsd, toPercent } from 'utils/formatters';
import moment from 'moment';

const fetchAssetBonds = async (): Promise<IAssetBond[]> => {
  const res1 = await ElyfiSubgraph.getAssetBonds();
  const res2 = await ElyfiSubgraph.getAssetBondsBSC();

  return [
    ...res1.data.data.assetBondTokens,
    ...res2.data.data.assetBondTokens
  ]
}

function Asset() {
  const [abs, setAbs] = useState<IAssetBond[]>([]);
  const [metaData, setMetadata] = useState<{ hash: string, link: string }[][]>([]);

  useEffect(() => {
    fetchAssetBonds().then((res) => {
      setAbs(res)
    })
  }, [])

  useEffect(() => {
    if (abs.length === 0) return;

    Promise.all(
      abs.map(async (ab) => {
        try {
          const response = await Slate.fetctABTokenIpfs(ab.ipfsHash);
          const contractDoc = response.data;
          return [
            {
              hash: contractDoc.documents[0].hash,
              link: contractDoc.documents[0].link,
            },
            {
              hash: contractDoc.documents[1].hash,
              link: contractDoc.documents[1].link,
            },
            {
              hash: contractDoc.documents[2].hash,
              link: contractDoc.documents[2].link,
            },
            {
              hash: contractDoc.images[0]?.hash,
              link: contractDoc.images[0]?.link,
            },
          ]
        } catch (e) {
          return [
            {
              hash: "",
              link: "",
            },
            {
              hash: "",
              link: "",
            },
            {
              hash: "",
              link: "",
            },
            {
              hash: "",
              link: "",
            },
          ]
        }
      })
    ).then((res) => {
      setMetadata(res)
    })
  }, [abs])

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        style={{
          marginBottom: 20,
          margin: "auto"
        }}
      >
        <List>
          {
            abs.map((ab, index) => {
              const parsedId = parseTokenId(ab.id);
              console.log(parsedId)
              const decimal = ab.reserve.id === '0x87a612ae553d0ea6eec1798ce1aaf6f2ce80568f' ? 6 : 18;
              const meta = metaData[index];
              if (!meta) {
                return <></>
              }
              return (
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt="Remy Sharp" src={metaData[index][3].link} />
                  </ListItemAvatar>
                  <ListItemText
                    primary="대출번호"
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          parsedId.nonce
                        }
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary={`대출금`}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {toUsd(ab.principal || '0', decimal)}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary={`채권 이자율`}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {toPercent(ab.couponRate || '0')}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary={`연체 이자율`}
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {toPercent(ab.delinquencyRate || '0')}
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="대출일"
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          ab.loanStartTimestamp
                            ? moment(ab.loanStartTimestamp * 1000).format(
                              'YYYY.MM.DD',
                            )
                            : '-'
                        }
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="만기일"
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          ab.maturityTimestamp
                            ? moment(ab.maturityTimestamp * 1000).format(
                              'YYYY.MM.DD',
                            )
                            : '-'
                        }
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="채권최고액"
                    secondary={
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {
                          toUsd(ab.debtCeiling || '0', decimal)
                        }
                      </Typography>
                    }
                  />
                  <ListItemText
                    primary="등기"
                    secondary={
                      <Button
                        color="inherit"
                        href={metaData[index][0].link}
                      >
                        링크
                      </Button>
                    }
                  />
                  <ListItemText
                    primary="계약서"
                    secondary={
                      <Button
                        color="inherit"
                        href={metaData[index][1].link}
                      >
                        링크
                      </Button>
                    }
                  />
                  <ListItemText
                    primary="법인등기"
                    secondary={
                      <Button
                        color="inherit"
                        href={metaData[index][2].link}
                      >
                        링크
                      </Button>
                    }
                  />
                </ListItem>
              )
            })
          }
        </List>
      </Box>
    </div>
  );
}

export default Asset;
