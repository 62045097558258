import { useState, useEffect, useContext } from 'react';
import generateTokenId, { AssetBondIdData } from 'utils/generateTokenId';
import reverseGeocoding from "utils/reverseGeocoding";
import {
  TextField,
  Typography,
  Box,
  TableBody,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Button,
  IconButton,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GoogleMapReact from 'google-map-react';
import Marker from 'components/Marker';
import { StorageContext } from 'providers/StorageProvider';

const AssetTemplate = {
  nonce: 6,
  countryCode: 82,
  collateralServiceProviderIdentificationNumber: 2201110189192,
  collateralLatitude: 3765932,
  collateralLatitudeSign: 1,
  collateralLongitude: 12706192,
  collateralLongitudeSign: 1,
  collateralDetail: 301,
  collateralCategory: 1,
  productNumber: 1,
}

const DefiTemplate = {
  nonce: 100001,
  countryCode: 82,
  collateralServiceProviderIdentificationNumber: 2201110189192,
  collateralLatitude: 0,
  collateralLatitudeSign: 0,
  collateralLongitude: 0,
  collateralLongitudeSign: 0,
  collateralDetail: 0,
  collateralCategory: 4,
  productNumber: 5,
}

const TokenIdMaker = () => {
  const { tokenIds, setTokenId } = useContext(StorageContext)
  const [state, setState] = useState<AssetBondIdData>({
    nonce: 6,
    countryCode: 82,
    collateralServiceProviderIdentificationNumber: 2201110189192,
    collateralLatitude: 3765932,
    collateralLatitudeSign: 1,
    collateralLongitude: 12706192,
    collateralLongitudeSign: 1,
    collateralDetail: 301,
    collateralCategory: 1,
    productNumber: 1,
  })
  const [name, setName] = useState('Test Name');

  const [address, setAddress] = useState({
    ko: "-",
    en: "-"
  })

  const lat = state.collateralLatitude / 100000;
  const lng = state.collateralLongitude / 100000;

  const loadAddress = async (lat: number, lng: number) => {
    setAddress({
      ko: await reverseGeocoding(lat, lng, "ko"),
      en: await reverseGeocoding(lat, lng, "en")
    })
  }

  useEffect(() => {
    loadAddress(lat, lng)
  }, [lat, lng])

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            required
            id="outlined-required"
            label="nonce"
            value={name}
            onChange={(event) => { setName(event.target.value) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="nonce"
            value={state.nonce}
            onChange={(event) => { setState({ ...state, nonce: parseInt(event.target.value) }) }}
          />
          <TextField
            required
            id="outlined-required"
            label="countryCode"
            value={state.countryCode}
            onChange={(event) => { setState({ ...state, countryCode: parseInt(event.target.value) }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="CSP ID"
            value={state.collateralServiceProviderIdentificationNumber}
            onChange={(event) => { setState({ ...state, collateralServiceProviderIdentificationNumber: parseInt(event.target.value) }) }}
          />
          <TextField
            required
            id="outlined-required"
            label="collateralDetail"
            value={state.collateralDetail}
            onChange={(event) => { setState({ ...state, collateralDetail: parseInt(event.target.value) }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="collateralCategory"
            value={state.collateralCategory}
            onChange={(event) => { setState({ ...state, collateralCategory: parseInt(event.target.value) }) }}
            helperText="1=아파트, 2=상가, 3=오피스텔, 4=others"
          />
          <TextField
            required
            id="outlined-required"
            label="productNumber"
            value={state.productNumber}
            onChange={(event) => { setState({ ...state, productNumber: parseInt(event.target.value) }) }}
            helperText="1=주택담보, 2=경매잔금, 3=전세반환금단기, 4=상가담보, 5=others"
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="Latitude"
            value={state.collateralLatitude}
            onChange={(event) => { setState({ ...state, collateralLatitude: parseInt(event.target.value) }) }}
            helperText="소수점 5자리까지 표기하되, 소수점 제외"
          />
          <TextField
            required
            id="outlined-required"
            label="LatitudeSign"
            value={state.collateralLatitudeSign}
            onChange={(event) => { setState({ ...state, collateralLatitudeSign: parseInt(event.target.value) }) }}
            helperText="1은 양수를 의미"
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="Longitude"
            value={state.collateralLongitude}
            onChange={(event) => { setState({ ...state, collateralLongitude: parseInt(event.target.value) }) }}
            helperText="소수점 5자리까지 표기하되, 소수점 제외"
          />
          <TextField
            required
            id="outlined-required"
            label="LongitudeSign"
            value={state.collateralLongitudeSign}
            onChange={(event) => { setState({ ...state, collateralLongitudeSign: parseInt(event.target.value) }) }}
            helperText="1은 양수를 의미"
          />
        </div>
        <Button
          variant="contained"
          onClick={() => {
            const id = generateTokenId(state).toString();
            if (id) {
              setTokenId([
                ...tokenIds,
                {
                  id,
                  nonce: state.nonce,
                  name,
                }
              ])
            }
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setState(DefiTemplate)
          }}
        >
          defi template
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setState(AssetTemplate)
          }}
        >
          asset template
        </Button>
      </Box>
      <Box style={{ marginTop: 50 }}>
        <Typography variant="h3">
          Preview
        </Typography>
        <Typography >
          {`Token ID : ${generateTokenId(state).toString()}`}
        </Typography>
        <Typography >
          {`도로명 주소 : ${address.ko}`}
        </Typography>
        <Typography >
          {`영문 주소 : ${address.en}`}
        </Typography>
        <div style={{ width: 400, height: 400, margin: 'auto', marginBottom: 100 }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY! }}
            center={{
              lat, lng
            }}
            defaultZoom={15}
          >
            <Marker lat={lat} lng={lng} />
          </GoogleMapReact>
        </div>
      </Box >
      {
        tokenIds.length > 0 &&
        <Box>
          <Typography variant="h3">
            History
          </Typography>
          <div style={{ width: 800, margin: "auto" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left"> name </TableCell>
                  <TableCell> tokenId </TableCell>
                  <TableCell> nonce </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  tokenIds.map((tokenId, index) => {
                    return (
                      <TableRow>
                        <TableCell align="left"> {tokenId.name}</TableCell>
                        <TableCell> {tokenId.id}</TableCell>
                        <TableCell align="right"> {tokenId.nonce}</TableCell>
                        <TableCell align="right">
                          <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                              setTokenId(tokenIds.filter((_item, tIndex) => tIndex !== index))
                            }}
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </div>
        </Box>
      }
    </div>
  );
}

export default TokenIdMaker;
