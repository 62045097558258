import { Storage } from '@aws-amplify/storage';
import {
  Box,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import Hash from 'ipfs-only-hash';
import moment from 'moment';
import { isConstructorDeclaration } from 'typescript';

const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

Storage.configure({
  customPrefix: {
    public: 'ipfs/',
  },
  bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
  region: process.env.REACT_APP_AWS_REGION,
  level: "public",
  identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID
})

function Uploader() {
  const [s3Files, setS3Files] = useState<{ lastModified: Date | undefined, key: string | undefined, size: number | undefined }[]>([])
  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState<Boolean>(false);

  const fetchS3Files = async () => {
    const res = await Storage.list("")
    res.sort((a, b) => moment(b.lastModified).diff(moment(a.lastModified)))
    setS3Files(res)
  }

  useEffect(() => {
    fetchS3Files()
  }, [])

  const upload = async () => {
    if(!files) return;

    setLoading(true);

    const promises = await Promise.allSettled(files.map(async (file) => {
      const base64 = (await toBase64(file)) as string;
      const buffer = Buffer.from(base64.split(',')[1], "base64");
      const cid = await Hash.of(buffer, { cidVersion: 1, rawLeaves: true })

      await Storage.put(cid, file, {
        contentType: file.type,
        acl: "public-read"
      })
    }))

    fetchS3Files();
    setLoading(false);
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (!event.target.files) return;

    setFiles(Array.from(event.target.files))
  }

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        style={{
          marginBottom: 20
        }}
      >
        <input id="file" multiple={true} type="file" onChange={handleFileChange} />
        {
          !loading &&
          <button
            onClick={(e) => {
              e.preventDefault();
              upload()
            }}
          >
            Upload File
          </button>
        }
      </Box>
      <div>
        {
          s3Files.filter((_, index) => index <= 10 ).map((file, index) => {
            if(!file.key) return <></>

            return (
              <div key={index}>
                <a href={`https://elysia-public.s3.ap-northeast-2.amazonaws.com/ipfs/${file.key}`}> {file.key}</a>
                <div>
                  {moment(file.lastModified).fromNow()}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default Uploader