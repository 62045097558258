import Token from 'enums/Token';

export const templateData = {
  tokenId:
    '115792089237316195422003243566959780588426732429207118347401154748402314070022',
  moneypool: Token.DAI,
  borrower: '0xc1e4E90bCF0152AA5E5bB92caDeF87be15885413',
  signer: '0x7d03A06873eadc3334e7F705BeF813FAdfFC0A5D',
  principal: '2576.89',
  debtCeiling: '231332.44',
  couponRate: '7.5',
  delinquencyRate: '3',
  loanStartTimeYear: 2022,
  loanStartTimeMonth: 10,
  loanStartTimeDay: 15,
  loanDuration: 328,
  ipfsHash: 'bafkreigzg3nbuuf472geitlgduaywqak5gzjlojehpklrffmbfczliun64',
} as SettleData;

export const emptyData = {
  tokenId: '',
  moneypool: Token.DAI,
  borrower: '',
  signer: '',
  principal: '',
  debtCeiling: '',
  couponRate: '',
  delinquencyRate: '',
  loanStartTimeYear: 2021,
  loanStartTimeMonth: 1,
  loanStartTimeDay: 1,
  loanDuration: 1,
  ipfsHash: '',
} as SettleData;

type SettleData = {
  tokenId: string;
  moneypool: Token;
  borrower: string;
  signer: string;
  principal: string;
  debtCeiling: string;
  couponRate: string;
  delinquencyRate: string;
  loanStartTimeYear: number;
  loanStartTimeMonth: number;
  loanStartTimeDay: number;
  loanDuration: number;
  ipfsHash: string;
};

export default SettleData;
