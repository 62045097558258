import { createContext, useEffect } from 'react';
import { useState } from 'react';
import SettleData, { emptyData } from 'types/SettleData';

interface TokenId {
  id: string
  nonce: number
  name: string
}

interface IStorage {
  tokenIds: TokenId[]
  settleData: SettleData
}

interface IStorageContext extends IStorage {
  setTokenId: (tokenIds: TokenId[]) => void
  setSettleData: (settleData: SettleData) => void
}

export const StorageContext = createContext<IStorageContext>({} as IStorageContext);

const StorageProvider: React.FC = (props) => {
  const [state, setState] = useState<IStorage>({
    tokenIds: [],
    settleData: emptyData,
  });

  const setTokenId = (tokenIds: TokenId[]) => {
    setState({
      ...state,
      tokenIds
    })

    window.localStorage.setItem('@tokenIds', JSON.stringify(tokenIds))
  };

  const setSettleData = (settleData: SettleData) => {
    setState({
      ...state,
      settleData
    })

    window.localStorage.setItem('@settleData', JSON.stringify(settleData))
  };

  useEffect(() => {
    const tokenIds = JSON.parse(window.localStorage.getItem('@tokenIds') || '[]') as TokenId[]
    const settleData = JSON.parse(window.localStorage.getItem('@settleData') || '[]') as SettleData

    setState({
      tokenIds,
      settleData: settleData || emptyData
    })
  }, [])

  return (
    <StorageContext.Provider value={{
      ...state,
      setTokenId,
      setSettleData
    }}>
      {props.children}
    </StorageContext.Provider>
  );
}

export default StorageProvider;