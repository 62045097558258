import {
  Box,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from '@mui/material';
import { StorageContext } from 'providers/StorageProvider';
import { useContext, useState } from 'react';
import { emptyData, templateData } from 'types/SettleData';
import { utils } from 'ethers';
import getTokenAddresses from 'utils/getTokenAddresses';
import Token from 'enums/Token';
import getTokenDecimal from 'utils/getTokenDecimal';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import tokenizerAbi from 'utils/tokenizerAbi';
import getEnv from 'utils/getEnv';

function SafeSnap() {
  const { tokenIds, settleData, setSettleData } = useContext(StorageContext);

  const [state, setState] = useState(templateData);
  const tokenierAddress = getTokenAddresses(
    state.moneypool,
    state.moneypool === Token.BUSD ? 56 : 1,
  ).tokenizerAddress;
  const moneypoolAddress = getEnv(
    state.moneypool === Token.BUSD ? 56 : 1,
  ).moneypool;

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        style={{
          marginBottom: 20,
        }}
      >
        <FormControl style={{ marginRight: 20 }}>
          <InputLabel id="token-id-label">token ID</InputLabel>
          <Select
            labelId="token-id-label"
            id="token-id-select"
            label="token id"
            value={state.tokenId || 'TokenId'}
            onChange={(event) => {
              setState({ ...state, tokenId: event.target.value });
            }}
            style={{ width: 300, marginBottom: 20 }}
          >
            {tokenIds.map((tokenId, index) => {
              return (
                <MenuItem key={index} value={tokenId.id}>
                  {tokenId.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="moneypool-label">Moneypool</InputLabel>
          <Select
            labelId="moneypool-label"
            id="moneypool-select"
            label="moneypool"
            value={state.moneypool}
            onChange={(event) => {
              setState({
                ...state,
                moneypool: event.target.value as Token,
                signer:
                  event.target.value === 'BUSD'
                    ? '0x1Ba25f40bA5BEFcffef536709271e3098345b0Cc' // BSC EOA
                    : '0x7d03A06873eadc3334e7F705BeF813FAdfFC0A5D', // mainnet elyfi safe snap
              });
            }}
            style={{ width: 300 }}
          >
            <MenuItem value={Token.DAI}>DAI</MenuItem>
            <MenuItem value={Token.USDT}>USDT</MenuItem>
            <MenuItem value={Token.BUSD}>BUSD</MenuItem>
            <MenuItem value={Token.USDC}>USDC</MenuItem>
          </Select>
        </FormControl>
        <div>
          <TextField
            required
            id="outlined-required"
            label="대출자 주소"
            value={state.borrower}
            onChange={(event) => {
              setState({ ...state, borrower: event.target.value });
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="서명인 주소"
            value={state.signer}
            onChange={(event) => {
              setState({ ...state, signer: event.target.value });
            }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="대출금"
            value={state.principal}
            onChange={(event) => {
              setState({ ...state, principal: event.target.value });
            }}
            helperText="소수점 둘째 자리까지 표기, 달러 기준"
          />
          <TextField
            required
            id="outlined-required"
            label="채권최고액"
            value={state.debtCeiling}
            onChange={(event) => {
              setState({ ...state, debtCeiling: event.target.value });
            }}
            helperText="소수점 둘째 자리까지 표기, 달러 기준"
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="채권 이자율"
            value={state.couponRate}
            onChange={(event) => {
              setState({
                ...state,
                couponRate: event.target.value,
              });
            }}
            helperText="%기준, 7 -> 7%"
          />
          <TextField
            required
            id="outlined-required"
            label="연체이자율"
            value={state.delinquencyRate}
            onChange={(event) => {
              setState({ ...state, delinquencyRate: event.target.value });
            }}
            helperText="%기준"
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="대출시작년도"
            value={state.loanStartTimeYear}
            onChange={(event) => {
              setState({
                ...state,
                loanStartTimeYear: parseInt(event.target.value || '0'),
              });
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="대출시작월"
            value={state.loanStartTimeMonth}
            onChange={(event) => {
              setState({
                ...state,
                loanStartTimeMonth: parseInt(event.target.value || '0'),
              });
            }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="대출시작일"
            value={state.loanStartTimeDay}
            onChange={(event) => {
              setState({
                ...state,
                loanStartTimeDay: parseInt(event.target.value || '0'),
              });
            }}
          />
          <TextField
            required
            id="outlined-required"
            label="대출기간"
            value={state.loanDuration}
            onChange={(event) => {
              setState({
                ...state,
                loanDuration: parseInt(event.target.value || '0'),
              });
            }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="ipfs hash"
            value={state.ipfsHash}
            onChange={(event) => {
              setState({ ...state, ipfsHash: event.target.value });
            }}
          />
        </div>
      </Box>
      <div style={{ marginBottom: 20 }}>
        <Button
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={() => {
            setSettleData(state);
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={() => {
            setState(settleData);
          }}
        >
          Load
        </Button>
        <Button
          variant="contained"
          style={{ marginRight: 20 }}
          onClick={() => {
            setState(templateData);
          }}
        >
          Template
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setState(emptyData);
          }}
        >
          Reset
        </Button>
      </div>
      <div>
        <h1>SafeSnap Content</h1>
        <h2>1. MintAssetBond </h2>
        <div>To: {tokenierAddress}</div>
        <div>값 : 0</div>
        <CopyToClipboard text={JSON.stringify(tokenizerAbi)}>
          <button>ABI 복사하기</button>
        </CopyToClipboard>
        <h2></h2>
        <div>account: 0x54Df9a653124889472B27Be231aC81e15EB08EEE</div>
        <div>tokenId: {state.tokenId}</div>

        <h2>2. SettleAssetBond </h2>
        <div>To: {tokenierAddress}</div>
        <div>값 : 0</div>
        <CopyToClipboard text={JSON.stringify(tokenizerAbi)}>
          <button>ABI 복사하기</button>
        </CopyToClipboard>
        <h2></h2>
        <div>borrower: {state.borrower}</div>
        <div>signer: {state.signer}</div>
        <div>tokenId: {state.tokenId}</div>
        <div>
          principal:
          {utils
            .parseUnits(
              state.principal || '0',
              getTokenDecimal(state.moneypool),
            )
            .toString()}
        </div>
        <div>
          couponRate: {utils.parseUnits(state.couponRate || '0', 25).toString()}
        </div>
        <div>
          delinquencyRate:{' '}
          {utils.parseUnits(state.delinquencyRate || '0', 25).toString()}
        </div>
        <div>
          debtCeiling:
          {utils
            .parseUnits(
              state.debtCeiling || '0',
              getTokenDecimal(state.moneypool),
            )
            .toString()}
        </div>
        <div>loanDuration: {state.loanDuration}</div>
        <div>loanStartTimeYear: {state.loanStartTimeYear}</div>
        <div>loanStartTimeMonth: {state.loanStartTimeMonth}</div>
        <div>loanStartTimeDay: {state.loanStartTimeDay}</div>
        <div>ipfsHash: {state.ipfsHash}</div>
      </div>
      <h1></h1>
      <h2>3. Approve </h2>
      <div>To: {tokenierAddress}</div>
      <div>값 : 0</div>
      <CopyToClipboard text={JSON.stringify(tokenizerAbi)}>
        <button>ABI 복사하기</button>
      </CopyToClipboard>
      <h2></h2>
      <div>to: {moneypoolAddress}</div>
      <div>tokenId: {state.tokenId}</div>
      <h1></h1>
    </div>
  );
}

export default SafeSnap;
