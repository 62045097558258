import axios, { AxiosResponse } from 'axios';
import { BigNumber } from 'ethers';

export interface IAssetBond {
  id: string
  state: number
  signer: {
    id: string
  }
  borrower: {
    id: string
  }
  collateralServiceProvider: {
    id: string
  }
  reserve: {
    id: string
  }
  principal: BigNumber
  debtCeiling: BigNumber
  couponRate: BigNumber
  interestRate: BigNumber
  delinquencyRate: BigNumber
  loanStartTimestamp: number
  collateralizeTimestamp: number
  maturityTimestamp: number
  liquidationTimestamp: number
  ipfsHash: string
  signerOpinionHash: string
  liquidity: BigNumber;
  depositedToken0: string;
  depositedToken1: string;
}

interface IAssetBondTokenResponse {
  data: {
    assetBondTokens: IAssetBond[]
  };
}

const baseUrl = 'https://api.thegraph.com/subgraphs/name/donguks/elyfiropsten';
const bsc = 'https://api.thegraph.com/subgraphs/name/donguks/elyfi-bsc-subgraph';

export class ElyfiSubgraph {
  static getAssetBonds = async (): Promise<AxiosResponse<IAssetBondTokenResponse>> => {
    return axios.post(baseUrl, {
      query: `
        {
          assetBondTokens(
            orderBy: loanStartTimestamp
            orderDirection: desc
            where: { state_gt: 0 }
          ) {
            id
            state
            signer {
              id
            }
            borrower {
              id
            }
            collateralServiceProvider {
              id
            }
            reserve {
              id
            }
            principal
            debtCeiling
            couponRate
            interestRate
            delinquencyRate
            loanStartTimestamp
            collateralizeTimestamp
            maturityTimestamp
            liquidationTimestamp
            ipfsHash
            signerOpinionHash
          }
        }
        `,
    });
  };

  static getAssetBondsBSC = async (): Promise<AxiosResponse<IAssetBondTokenResponse>> => {
    return axios.post(bsc, {
      query: `
        {
          assetBondTokens(
            orderBy: loanStartTimestamp
            orderDirection: desc
            where: { state_gte: 0 }
          ) {
            id
            state
            signer {
              id
            }
            borrower {
              id
            }
            collateralServiceProvider {
              id
            }
            reserve {
              id
            }
            principal
            debtCeiling
            couponRate
            interestRate
            delinquencyRate
            loanStartTimestamp
            collateralizeTimestamp
            maturityTimestamp
            liquidationTimestamp
            ipfsHash
            signerOpinionHash
          }
        }
        `,
    });
  }
}

export default ElyfiSubgraph;