import EnvironmentVariables from "types/EnvironmentVariables";

const bscTest = {
  moneypool: '0xBdbCC95abF4172a54e24Dc9A9e5E7f966cB6C9fC',
  busd: {
    address: '0xC93b97b01455454fBa11B806ea645d021212c4C9',
    tokenizerAddress: '0xD1c90C98fa280db16DE789a4FF15a9108829ec9A',
  },
} as EnvironmentVariables;

export default bscTest;
