import Token from "enums/Token";
import getEnv from "./getEnv";

const getTokenAddresses = (token: Token, chainId: number): { address: string, tokenizerAddress: string } => {
  switch (token) {
    case Token.DAI:
      return getEnv(chainId).dai;
    case Token.BUSD:
      return getEnv(chainId).busd;
    case Token.USDT:
      return getEnv(chainId).usdt;
    case Token.USDC:
      return getEnv(chainId).usdc;
    default:
      return getEnv(chainId).dai
  }
}

export default getTokenAddresses