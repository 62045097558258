import React, { useContext } from "react";
import {
  Button,
  Chip,
  Typography,
} from '@mui/material';
import { Web3Context } from "providers/Web3Provider";
import FaceIcon from '@mui/icons-material/Face';

const TransactionButton: React.FC<{ handler: () => void, label: string, error: string }> = ({
  handler,
  label,
  error,
}) => {
  const { connected, address, reset, connect } = useContext(Web3Context)

  if (connected) {
    return (
      <>
        <div style={{ marginBottom: 20 }}>
          <Chip icon={<FaceIcon />} label={address} variant="outlined" onDelete={reset} />
        </div>
        <Button variant="contained" onClick={handler}>{label}</Button>
        <Typography color="error">
          {error}
        </Typography>
      </>

    )
  } else {
    return (
      <Button variant="contained" onClick={connect}>Connect Wallet</Button>
    )
  }
}

export default TransactionButton;