import EnvironmentVariables from "types/EnvironmentVariables";

const bsc = {
  moneypool: '0x0bdFef5f8B75741d33a22d85022244CBE793DA24',
  busd: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    tokenizerAddress: '0x0d768c1507B5099CB37e5D28B1959B831B5EbF9e',
  },
} as EnvironmentVariables;

export default bsc;