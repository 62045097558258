import { useState, useContext } from 'react';
import {
  TextField,
  Typography,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import ReactJson from 'react-json-view';
import { StorageContext } from 'providers/StorageProvider';

// tokenId
// docs
// image
const DataGenreator = () => {
  const { tokenIds } = useContext(StorageContext)
  const [state, setState] = useState({
    tokenId: '',
    image: 'bafybeigzbyfcekqcwqflh6tcmtjmqz4o7q4cvjzphsyaukduw73shpi3am',
    doc0: 'bafkreiei23lnkedef6bcsln76ng62pw5x4vczr3e45hrpzuydu5l7552ea',
    doc1: 'bafybeig7ucnwb5evnigjua33gqowx54krbnjvterlqrakuy45huyfmzz5m',
    doc2: 'bafybeidtfourbfi4oy3nlos4v7vmvn3oyy5ufbtxjdux2gnl3al5pyutsy',
    exchangeRateKRW: "0.00084"
  })

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <FormControl >
            <InputLabel id="demo-simple-select-label">token ID</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="token id"
              value={state.tokenId || 'TokenId'}
              onChange={(event) => { setState({ ...state, tokenId: event.target.value }) }}
              style={{ width: 300 }}
            >
              {
                tokenIds.map((tokenId) => {
                  return (
                    <MenuItem value={tokenId.id}>{tokenId.name}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="image"
            value={state.image}
            onChange={(event) => { setState({ ...state, image: event.target.value }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="등기"
            value={state.doc0}
            onChange={(event) => { setState({ ...state, doc0: event.target.value }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="원리금수취권"
            value={state.doc1}
            onChange={(event) => { setState({ ...state, doc1: event.target.value }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="사업자등록증"
            value={state.doc2}
            onChange={(event) => { setState({ ...state, doc2: event.target.value }) }}
          />
        </div>
        <div>
          <TextField
            required
            id="outlined-required"
            label="KRW / USD 환율"
            value={state.exchangeRateKRW}
            onChange={(event) => { setState({ ...state, exchangeRateKRW: event.target.value }) }}
          />
        </div>
      </Box>
      <Box style={{ marginTop: 50 }}>
        <Typography variant="h3">
          Preview
        </Typography>
        <ReactJson
          style={{ textAlign: 'initial', width: 1000, margin: 'auto' }}
          name={false}
          displayDataTypes={false}
          src={{
            version: "v1",
            tokenId: state.tokenId,
            description: "Information related to the collateral of ABToken in ELYFI protocol",
            exchangeRateKRW: state.exchangeRateKRW,
            images: [{
              "type": 0,
              "hash": state.image,
              "provider": "pinata",
              "link": `https://elysia.mypinata.cloud/ipfs/${state.image}`
            }],
            documents: [state.doc0, state.doc1, state.doc2].map((doc, index) => {
              return {
                "type": index,
                "hash": doc,
                "provider": "pinata",
                "link": `https://elysia.mypinata.cloud/ipfs/${doc}`
              }
            })
          }}
          theme="monokai"
        />
      </Box >
    </div >
  );
}

export default DataGenreator