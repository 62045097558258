import { Button, AppBar, Toolbar } from '@mui/material';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './App.css';
import TokenIdMaker from 'pages/TokenIdMaker';
import DataGenerator from 'pages/DataGenerator';
import Sign from 'pages/Sign';
import Borrow from 'pages/Borrow';
import Approve from 'pages/Approve';
import StorageProvider from 'providers/StorageProvider';
import Web3Provider from 'providers/Web3Provider';
import Asset from 'pages/Asset';
import Repay from 'pages/Repay';
import Liquidate from 'pages/Liquidate';
import Uploader from 'pages/Uploader';
import SafeSnap from 'pages/SafeSnap';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_WEB_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
  Storage: {
    bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
  },
});

function App() {
  return (
    <Authenticator>
      <div className="App">
        <StorageProvider>
          <Web3Provider>
            <AppBar position="static" color="transparent">
              <Toolbar>
                <Button color="inherit" href="/tokenIdMaker">
                  Token ID
                </Button>
                <Button color="inherit" href="/dataGenerator">
                  IPFS
                </Button>
                <Button color="inherit" href="/uploader">
                  Uploader
                </Button>
                <Button color="inherit" href="/safeSnap">
                  SafeSnap
                </Button>
                <Button color="inherit" href="/approve">
                  Approve
                </Button>
                <Button color="inherit" href="/sign">
                  Sign
                </Button>
                <Button color="inherit" href="/borrow">
                  Borrow
                </Button>
                <Button color="inherit" href="/Asset">
                  Asset
                </Button>
                <Button color="inherit" href="/repay">
                  Repay
                </Button>
                <Button color="inherit" href="/liquidate">
                  Liquidate
                </Button>
              </Toolbar>
            </AppBar>
            <div style={{ height: 50 }} />
            <Router>
              <Switch>
                <Route path="/tokenIdMaker">
                  <TokenIdMaker />
                </Route>
                <Route path="/safeSnap">
                  <SafeSnap />
                </Route>
                <Route path="/dataGenerator">
                  <DataGenerator />
                </Route>
                <Route path="/uploader">
                  <Uploader />
                </Route>
                <Route path="/sign">
                  <Sign />
                </Route>
                <Route path="/approve">
                  <Approve />
                </Route>
                <Route path="/borrow">
                  <Borrow />
                </Route>
                <Route path="/repay">
                  <Repay />
                </Route>
                <Route path="/liquidate">
                  <Liquidate />
                </Route>
                <Route path="/Asset">
                  <Asset />
                </Route>
              </Switch>
            </Router>
          </Web3Provider>
        </StorageProvider>
      </div>
    </Authenticator>
  );
}

export default App;
