import mainnetEnv from "envs/mainnet";
import ropstenEnv from "envs/ropsten";
import bscTest from "envs/bscTest";
import bsc from "envs/bsc";
import EnvironmentVariables from "types/EnvironmentVariables";

const getEnv = (chainId: number): EnvironmentVariables => {
  switch (chainId) {
    case 1: return mainnetEnv;
    case 97: return bscTest;
    case 56: return bsc;
    default: return ropstenEnv
  }
}

export default getEnv;