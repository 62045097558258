import { ethers } from 'ethers'
import BigNumber from 'bignumber.js';

export const bigBinaryStringToHexString = (bigBinaryString: string) => {
  const bigNumberFromJS = new BigNumber(bigBinaryString, 2).toFixed();
  const bigNumberInHex = ethers.BigNumber.from(bigNumberFromJS).toHexString();
  return bigNumberInHex.toLocaleUpperCase();
};

const assetBondIdDataDigits = {
  "nonce": 10,
  "countryCode": 12,
  "collateralServiceProviderIdentificationNumber": 50,
  "collateralLatitude": 28,
  "collateralLatitudeSign": 1,
  "collateralLongitude": 28,
  "collateralLongitudeSign": 1,
  "collateralDetail": 40,
  "collateralCategory": 10,
  "productNumber": 10
}

export type AssetBondIdData = {
  nonce: number;
  countryCode: number;
  collateralServiceProviderIdentificationNumber: number;
  collateralLatitude: number;
  collateralLatitudeSign: number;
  collateralLongitude: number;
  collateralLongitudeSign: number;
  collateralDetail: number;
  collateralCategory: number;
  productNumber: number;
};

export type AssetBondIdDataDigits = {
  nonce: number;
  countryCode: number;
  collateralServiceProviderIdentificationNumber: number;
  collateralLatitude: number;
  collateralLatitudeSign: number;
  collateralLongitude: number;
  collateralLongitudeSign: number;
  collateralDetail: number;
  collateralCategory: number;
  productNumber: number;
};

const getInformationFullBinary = (digit: number, info: number) => {
  const binaryFullLength = digit;
  const binary = info.toString(2);
  const binaryLength = binary.length;
  const result = '0'.repeat(binaryFullLength - binaryLength).concat(binary);
  return result;
};

const totalDigits = (assetBondIdDataDigits: AssetBondIdDataDigits) => {
  let result: number = 0;
  (Object.keys(assetBondIdDataDigits) as (keyof AssetBondIdDataDigits)[]).forEach((key) => {
    result += assetBondIdDataDigits[key];
  });
  return result;
};

const generateTokenId = (assetBondIdData: AssetBondIdData) => {
  /*
  if (
    !assetBondIdData.nonce
    || !assetBondIdData.collateralCategory
    || !assetBondIdData.countryCode
    || !assetBondIdData.collateralServiceProviderIdentificationNumber
    || !assetBondIdData.collateralLatitude
    || !assetBondIdData.collateralLatitudeSign
    || !assetBondIdData.collateralLongitude
    || !assetBondIdData.collateralLongitudeSign
    || !assetBondIdData.collateralDetail
    || !assetBondIdData.collateralCategory
    || !assetBondIdData.productNumber
  ) {
    return ''
  }
  */

  const bufferLength = totalDigits(assetBondIdDataDigits);
  let buffer = '1'.repeat(256 - bufferLength);
  (Object.keys(assetBondIdDataDigits).reverse() as (keyof AssetBondIdDataDigits)[]).forEach(
    (key) => {
      if (assetBondIdData[key] > 2 ** assetBondIdDataDigits[key]) {
        return 0;
      }
      buffer = buffer + getInformationFullBinary(assetBondIdDataDigits[key], assetBondIdData[key]);
    }
  );
  return ethers.BigNumber.from(bigBinaryStringToHexString(buffer).toLocaleLowerCase());
};

export default generateTokenId