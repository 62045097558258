import Token from "enums/Token";

const getTokenDecimal = (token: Token): number => {
  switch (token) {
    case Token.DAI:
      return 18
    case Token.BUSD:
      return 18
    case Token.USDT:
      return 6
    case Token.USDC:
      return 6
    default:
      return 0
  }
}

export default getTokenDecimal