import EnvironmentVariables from "types/EnvironmentVariables";

const mainnetEnv = {
  moneypool: '0xa93008fD32EB24E488DDAA6C0aA152559fDa9E8c',
  dai: {
    address: '0x6b175474e89094c44da98b954eedeac495271d0f',
    tokenizerAddress: '0xc6701e7be98a79485364419961838eb141141aaf',
  },
  usdt: {
    address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    tokenizerAddress: '0x68f69Ab21242e194ebd7534B598e26180dD92616',
  },
  usdc: {
    address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    tokenizerAddress: '0xD86f51C8d0F10AAd267fB42E143D6d0B97aE9B23',
  },
} as EnvironmentVariables;

export default mainnetEnv;
