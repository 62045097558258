import {
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from '@mui/material';
import { StorageContext } from 'providers/StorageProvider';
import { Web3Context } from 'providers/Web3Provider';
import { useContext, useState } from 'react';
import TransactionButton from 'components/TransactionButton';
import Token from 'enums/Token';
import getTokenAddresses from 'utils/getTokenAddresses';
import { TokenizerFactory } from '@elysia-dev/elyfi-v1-sdk';
import CopyToClipboard from 'react-copy-to-clipboard';
import tokenizerAbi from 'utils/tokenizerAbi';

function Sign() {
  const { tokenIds } = useContext(StorageContext);
  const { chainId, provider } = useContext(Web3Context);
  const [state, setState] = useState({
    tokenId: '',
    moneypool: Token.DAI,
  });
  const [error, setError] = useState('');
  const tokenierAddress = getTokenAddresses(
    state.moneypool,
    state.moneypool === Token.BUSD ? 56 : 1,
  ).tokenizerAddress;

  const Sign = async () => {
    if (!provider) return;

    try {
      await TokenizerFactory.connect(
        getTokenAddresses(state.moneypool, chainId).tokenizerAddress,
        provider.getSigner() as any,
      ).signAssetBond(state.tokenId, 'ok');
    } catch (e: any) {
      setError(e?.error?.message);
    }
  };

  return (
    <div>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        style={{
          marginBottom: 20,
        }}
      >
        <FormControl style={{ marginRight: 20 }}>
          <InputLabel id="token-id-label">token ID</InputLabel>
          <Select
            labelId="token-id-label"
            id="token-id-select"
            label="token id"
            value={state.tokenId || 'TokenId'}
            onChange={(event) => {
              setState({ ...state, tokenId: event.target.value });
            }}
            style={{ width: 300, marginBottom: 20 }}
          >
            {tokenIds.map((tokenId, index) => {
              return (
                <MenuItem key={index} value={tokenId.id}>
                  {tokenId.name}
                </MenuItem>
              );
            })}
          </Select>
          <TextField
            required
            id="outlined-required"
            label="tokenId 직접입력"
            value={state.tokenId}
            onChange={(event) => {
              setState({ ...state, tokenId: event.target.value });
            }}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="moneypool-label">Moneypool</InputLabel>
          <Select
            labelId="moneypool-label"
            id="moneypool-select"
            label="moneypool"
            value={state.moneypool}
            onChange={(event) => {
              setState({ ...state, moneypool: event.target.value as Token });
            }}
            style={{ width: 300 }}
          >
            <MenuItem value={Token.DAI}>DAI</MenuItem>
            <MenuItem value={Token.USDT}>USDT</MenuItem>
            <MenuItem value={Token.USDC}>USDC</MenuItem>
            <MenuItem value={Token.BUSD}>BUSD</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <TransactionButton handler={Sign} label={'Sign'} error={error} />
      <div>
        <h1>SafeSnap Content</h1>
        <h2>signAssetBond</h2>
        <div>To: {tokenierAddress}</div>
        <div>값 : 0</div>
        <CopyToClipboard text={JSON.stringify(tokenizerAbi)}>
          <button>ABI 복사하기</button>
        </CopyToClipboard>
        <h2></h2>
        <div>tokenId: {state.tokenId}</div>
        <div>signerOpinionHash: ok</div>
        <h2></h2>
      </div>
    </div>
  );
}

export default Sign;
