import EnvironmentVariables from "types/EnvironmentVariables";

const ropstenEnv = {
  moneypool: '0x6d5C3a745409B9bD8FA18B1b7425865113fcc91a',
  dai: {
    address: '0xef7a276e8d045aE60B13b4996A21a6636a5Ee6C0',
    tokenizerAddress: '0xbdda27ee9D42900fe35EcE1ea02d52c0b4736010',
  },
  usdt: {
    address: '0x87a612ae553d0ea6eec1798ce1aaf6f2ce80568f',
    tokenizerAddress: '0x85178AbAa18C246bb8A32A576B9A3485312156B7',
  },
  usdc: {
    address: '0x87a612ae553d0ea6eec1798ce1aaf6f2ce80568f',
    tokenizerAddress: '0x85178AbAa18C246bb8A32A576B9A3485312156B7',
  },
} as EnvironmentVariables;

export default ropstenEnv;
